.login {
  padding-left: 30px;
  padding-right: 30px;

  @media(min-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.login--data-room {
    @media(min-width: 768px) {
      left: 25%
    }
  }

  h1,
  h6 {
    text-align: center;
    font-weight: 400;
    font-size: 56px;
    padding-top: 100px;
    margin: 0 auto 30px;
    background: url(../../../assets/img/ico-lock.svg) no-repeat center top;
    background-size: 80px;

    &.no--lock {
      background: none;
    }

    @media (max-width: 768px) {
      font-size: 36px;
    }
  }

  h2 {
    text-align: center;
    font-weight: 400;
    font-size: 56px;
    padding-top: 100px;
    background: url(../../../assets/img/ico-lock.svg) no-repeat center top;
    background-size: 80px;
    margin: 0 auto 40px;
    max-width: 575px;

    @media (max-width: 768px) {
      font-size: 36px;
    }
  }

  p {
    text-align: center;
    font-weight: 400;
    font-size: 22px !important;
    margin: 0 0 72px;
    line-height: 1.4 !important;

    span.underline {
      cursor: pointer;
      text-decoration: underline;
    }

    &.empty {
      margin-bottom: 36px;
    }
  }

  form {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }

  label,
  input {
    display: block;
    width: 100%;
  }

  input[type=email],
  input[type=password],
  textarea {
    margin-bottom: 20px;
    padding: 10px 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #0c56ff;
  }

  textarea {
    width: 100%;
    resize: none;
  }

  input.error {
    border-color: red;
  }

  input[type=submit] {
    width: 114px;
    height: 44px;
    line-height: 44px;
    border-radius: 4px;
    background-color: #16171a;
    color: #fff;
    font-size: 16px !important;
    border: none;
    margin: 40px auto 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      background: #0c56ff;
    }

    &.cancel {
      color: #000;
      background: #eee;

      &:hover {
        background: rgba(158, 158, 158, 0.2);
      }
    }
  }

  a {
    display: block;
    color: #212529;
    text-align: center;
    padding: 12px 0;
  }

  .form-error {
    text-align: center;
    color: #ff0000;
    position: absolute;
    margin-top: -34px;
    margin-left: -23px;
    width: 346px;

    div {
      opacity: 0;
      transition: opacity 0.1s ease-in-out;

      &.visible {
        opacity: 1;
      }
    }
  }

  video {
    position: absolute;
    width: 80px;
    left: 50%;
    transform: translateX(-50px);
  }

  .sso-link {
    cursor: pointer;
    text-align: center;
    padding: 35px 0 0 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .forgot-link {
    cursor: pointer;
    text-align: right;
    padding: 0 0 10px;
    margin-top: -10px;

    a {
      padding: 0;
      text-align: right;
      text-decoration: none;
    }

    &:hover {
      a {
        text-decoration: underline;
      }
    }
  }
}

.data-room-side {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: calc(100vh - 40px);
  background: #000;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    max-width: 600px;
  }

  @media(max-width: 768px) {
    display: none;
  }
}