.module-colors {
	position: relative;
	margin: 0 auto;
	max-width: 1100px;

	&:hover {
		.module_settings {
			display: block;
		}
	}

	.col-xs-12 {
		display: flex;
		align-items: flex-end;
	}

	.module-colors__item {
		width: 100%;

		&.without-any-tags {
			overflow: hidden;
		}

		.title {
			&.without-any-tags {
				margin-top: 20px;
			}

			color: #969aa6;
			font-size: 18px;
			font-weight: 400;
			padding-bottom: 10px;

			p,
			h1,
			h2,
			h3 {
				margin: 20px 0 0;
			}

			p,
			h1,
			h2,
			h3 {
				margin-bottom: unset;
			}

			h1 {
				padding: 0;
				margin: 20px 0 0;
			}

			h1.linebreak-true,
			h2.linebreak-true,
			h3.linebreak-true,
			p.linebreak-true {
				margin: 0;
			}

			p {
				&.ql-align-justify {
					text-align: justify;
				}

				&.ql-align-center {
					text-align: center;
				}

				&.ql-align-right {
					text-align: right;
				}
			}

			h1, h2, h3 {
				&.ql-align-justify {
					text-align: justify;
				}

				&.ql-align-center {
					text-align: center;
				}

				&.ql-align-right {
					text-align: right;
				}
			}

			@media(max-width: 768px) {
				line-height: 1;
			}

			span {
				position: relative;
			}
		}

		.color-box {
			height: 262px;
			position: relative;
			border-radius: 4px;
			overflow: hidden;
			box-sizing: content-box;

			.color-mask {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: height 0.35s ease-in-out;

				@media(max-width: 768px) {
					display: none;
				}

				span {
					display: block;
					text-align: center;
					color: #fff;
					font-size: 18px;
					font-weight: 400;
					line-height: 30px;
					letter-spacing: normal;
					text-transform: uppercase;
					transition: opacity 0.2s ease-in-out;
					position: absolute;
					bottom: 10px;
					left: 50%;
					transform: translateX(-50%);

					@media(max-width: 768px) {
						font-size: 13px;
						line-height: 22px;
					}
				}
			}

			&:hover {
				.color-mask {
					height: 60px;

					span {
						opacity: 0;
					}
				}
			}
		}

		.color {
			height: 100%;
			background-color: #eee;
			text-align: center;
			font-size: 65px;
			color: #ccc;
			line-height: 200px;
		}

		.data {
			padding: 16px;
			background: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;

			div {
				color: #969aa6;
				font-size: 18px;
				font-weight: 400;
				line-height: 24px;
				font-family: 'Rubik';
				letter-spacing: normal;
				padding: 4px 0;
				text-transform: uppercase;

				span {
					position: relative;
					padding-right: 3px;

					.icon-block-copy-over {
						position: absolute;
						left: 10px;
						top: -37px;
						width: 16px;
						height: auto;
						display: none;
						padding-top: 0;
						padding-bottom: 0;
					}

					&.click-to-copy {
						cursor: pointer;

						&:before {
							content: "Copy";
							display: none;
							position: absolute;
							top: -43px;
							left: 0;
							text-transform: none;
							text-align: left;
							width: 75px;
							height: 32px;
							line-height: 32px;
							padding-left: 32px;
							background-position-x: 10px;
							background-size: 16px;
							border-radius: 32px;
						  font-size: 12px;
						  font-family: Rubik!important;
						  font-weight: 400;
						  background-color: #f6c944;
						  color: #000;
						}

						&:hover {
							background: #eee;

							&:before {
								display: none;
							}

							.icon-block-copy-over {
								display: none;
							}
						}
					}
				}

				&.pantone {
					text-transform: none;

					span {
						display: inline-block;
					}
				}

				&.custom_input {
					text-transform: none;
					line-height: 28px;
					padding-bottom: 0;
					display: inline-block;

					span {
						white-space: pre-wrap;
			    	word-wrap: break-word;
			    	word-break: break-word;
			    	padding-right: 0;
			    	display: inline-block;
					}
				}
			}
		}
	}
}

.module-colors.row.centered .data,
.module-colors.centered .data {
	display: flex;
	justify-content: center;
	align-items: center;
}

.module-colors.row.rounded .data,
.module-colors.rounded .data {
	display: flex;
	justify-content: center;
	align-items: center;
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
}

.module-colors.rounded {
	.custom-color-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.module-colors.rounded .module-colors__item .color-box:hover .color-mask {
	height: 0px;
}

.module--sides,
.module--nobottom {
	.module-colors {
		padding-bottom: 14px !important;
	}
}

.hide-copy-bubble::before {
	display: none !important;
}

.hide-copy-bubble .icon-block-copy-over {
	display: none !important;
}

.legacy-module-v-2, .legacy-module-v-1 {
	.hide-color-item-title-in-preview {
		padding-top: 0;
		align-items: flex-end;
		min-height: unset;
		.title {
			display: none;
		}
	}
}

.module-colors.rounded .module-colors__item .color-box .color-mask span {
	bottom: 50%;
	transform: translateX(-50%) translateY(50%);
}

.custom-color-overlay.only-on-hover-overlay {
	display: none;
}

.module-colors.rounded .module-colors__item .color-box:hover {
	.only-on-hover-overlay {
		display: block;
	}
}

.module-colors.rounded .module-colors__item .data {
	background-color: transparent !important;
}

@media(max-width: 768px) {
	.hide-color-item-title-in-preview {
		margin-top: 15px;
	}

	.module-colors.rounded .module-colors__item .color-box {
		.only-on-hover-overlay {
			display: block;
		}
	}

	.module-colors.rounded .module-colors__item .color-box .color-mask {
		display: block;
	}
}

.fixed-click-to-copy {
	cursor: pointer;
	position: relative;
	position: fixed;
	top: 0;
	display: none;
	z-index: 1;
	line-height: 1.4;
	font-family: Rubik!important;
	letter-spacing: normal;
	font-size: 20px !important;

	.icon-block-copy-over {
		width: 16px;
		position: absolute;
		left: 10px;
		top: 2px;
		width: 16px;
		height: auto;
		//display: none;
	}
}

.fixed-click-to-copy:before {
	content: "Copy";
	position: absolute;
	top: 0;
	left: 0;
	font-size: 14px;
	text-transform: none;
	text-align: left;
	width: 75px;
	height: 32px;
	line-height: 32px;
	padding-left: 32px;
	background-position-x: 10px;
	background-size: 16px;
	border-radius: 32px;
  font-size: 12px;
  font-family: Rubik!important;
  font-weight: 400;
  background-color: #f6c944;
  color: #000;
}