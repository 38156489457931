.assets {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 116px;
  height: 45px;
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 4px 7px 10px 3px rgba(0,0,0,.03);

  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  line-height: 1;
  color: #1c1e20;
  font-size: 14px;

  @media (max-width: 992px) {
    top: 60px;
    z-index: 9;
    display: none;
  }

  &__button {
    cursor: pointer;
    padding: 0 30px;
    line-height: 45px;
  }

  svg.back-to-guidelines-svg {
      //float: left;
      //margin-top: 10px;
      margin-right: 18px;
  }
}


.auth {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 20px;
  height: 45px;
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 4px 7px 10px 3px rgba(0,0,0,.03);

  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  line-height: 1;
  color: #1c1e20;
  font-size: 14px;

  @media (max-width: 992px) {
    top: 60px;
    z-index: 9;
  }

  &__button {
    cursor: pointer;
    padding: 0 30px;
    line-height: 45px;
  }

  &__avatar {
    padding: 7px 25px;
    padding-bottom: 15px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: none;
      cursor: pointer;
    }
  }
}

.dropdown-box {
  position: fixed;
  top: 48px;
  z-index: 9;
  min-width: 170px;
  right: 15px;
  min-height: 51px;

  @media (max-width: 992px) {
    top: 94px;
    z-index: 9;
  }
}

.dropdown {
  position: absolute;
  top: 4px;
  right: 0px;
  z-index: 9;
  min-width: 170px;
  padding: 0;
  margin: 0px 0 0;
  list-style: none;
  font-size: 14px;
  box-shadow: 4px 7px 10px 3px rgba(0,0,0,.03);
  border: 1px solid #dfe1e5;
  background-color: #fff;
  background-clip: padding-box;
  text-align: center;
  border-radius: 5px;

  &::before,
  &::after {
    bottom: 100%;
    right: 34px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::before {
    border-color: rgba(194,225,245,0);
    border-bottom-color: #e0e3e8;
    border-width: 8px;
  }

  &::after {
    border-color: rgba(136,183,213,0);
    border-bottom-color: #fff;
    border-width: 6px;
    right: 36px;
  }

  li {
    padding: 12px 20px;
    color: #a0a4b0;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Rubik' !important;
    line-height: 1.6 !important;
    letter-spacing: normal;
    border-top: 1px solid #e0e3e8;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:first-child {
      border: none;
    }

    &:hover {
      color: #262626;
    }

    a {
      color: #a0a4b0;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: #262626;
      }
    }
  }
}

.login-modal {
  width: 450px;
  height: 100%;
  background: #f5fafb;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  opacity: 1 !important;
  font-family: 'Rubik', sans-serif;
  transform: translateX(115%) scale(1) !important;
  transition: transform .8s cubic-bezier(0.25,0.8,0.25,1);
  overflow: hidden;
  border-radius: 0;
  box-shadow: -32px 0 87px -46px rgba(0,0,0,0.49);

  &.visible {
    transform: translateX(0) scale(1) !important;
    transition: transform .8s cubic-bezier(.25,.8,.25,1);
  }

  &__form {
    position: absolute;
    z-index: 2;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 260px;

    .form-error div {
      opacity: 0;
      padding-bottom: 30px;
      color: #ff0000;
      text-align: center;
      font-size: 13px;
      transition: opacity 0.2s ease-in-out;

      &.visible {
        opacity: 1;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border: 1px solid #16171a;
      -webkit-text-fill-color: #16171a;
      -webkit-box-shadow: 0 0 0px 1000px #000 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .form-group {
      height: 48px;
      position: relative;
      margin-bottom: 30px;

      label {
        display: block;
        position: absolute;
        top: 11px;
        z-index: -1;
        color: #16171a;
        font-size: 13px;
        font-weight: 500;
        margin: 0;
        transition: .2s ease-in-out;

        &.active {
          top: -10px;
          font-size: 11px;
        }
      }

      input[type=text],
      input[type=email],
      input[type=password] {
        width: 100%;
        font-size: 13px !important;
        font-family: 'Rubik', sans-serif !important;
        color: #16171a;
        background: none;
        border: none;
        border-bottom: 1px solid #0c56ff;
        padding: 9px 0;
        box-shadow: none;
      }

      input[type=submit] {
        width: 114px;
        height: 40px;
        border-radius: 3px;
        background-color: #16171a;
        color: #fff;
        font-size: 14px !important;
        font-family: 'Rubik', sans-serif !important;
        border: none;
        transition: background-color 0.2s ease-in-out;
        margin: 10px auto;
        display: block;
        cursor: pointer;

        &:hover {
          background-color: #0c56ff;
        }
      }
    }
  }
}

@media(max-width: 992px) {
  .assets {
    display: none;
  }
}
